import { XCircleIcon } from "@heroicons/react/20/solid";
import Layout from "../components/Layout";
import ReviewForm from "../components/ReviewForm";

const New = () => {
  return (
    <Layout
      headerHref="/"
      headerIcon={<XCircleIcon />}
      headerLabel="Cancel"
      headerButtonClass="cancelButton"
      heroSection={false}
      footerButton={false}
    >
      <ReviewForm />
    </Layout>
  );
};

export default New;
