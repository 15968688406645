import { useEffect, useState, useRef, useCallback } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { requestApi } from "../helpers";
import s from "../styles/List.module.scss";
import Card from "./Card";
import Button from "./Button";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/20/solid";
import { FaceFrownIcon } from "@heroicons/react/24/outline";
import { IRecommends, IReviews } from "../ts/interfaces";

const API_URL = process.env.REACT_APP_API_URL;
const queryUrl = API_URL + "/interviews";
const recommendUrl = API_URL + "/recommends";

const List = () => {
  const [reviews, setReviews] = useState<IReviews | null>();
  const [recommends, setRecommends] = useState<IRecommends | null>();
  const [filters, setFilters] = useState<any | null>([]);
  const [searchParams] = useSearchParams();
  const requestedRef = useRef(false);
  const [page, setPage] = useState(1);
  // @ts-ignore
  const currentParams = [...searchParams];
  const paramsUrlRef = useRef(JSON.stringify(currentParams));
  const createUrlWithParams = useCallback(() => {
    let requestUrl = API_URL + "/interviews?";
    filters.forEach((f: any[]) => (requestUrl += `${f[0]}=${f[1]}&`));
    return requestUrl;
  }, [filters]);

  useEffect(() => {
    // @ts-ignore
    const currentParams = [...searchParams];
    if (currentParams.length > 0) {
      if (JSON.stringify(filters) !== JSON.stringify(currentParams)) {
        setFilters(currentParams);
      }
    }
  }, [searchParams, filters]);

  useEffect(() => {
    if (!requestedRef.current) {
      requestApi(recommendUrl, setRecommends);
      if (!searchParams || window.location.pathname === "/interviews") {
        requestApi(queryUrl, setReviews);
      }
    }
    requestedRef.current = true;
  }, [searchParams]);
  useEffect(() => {
    if (requestedRef.current) {
      // @ts-ignore
      const params = [...searchParams];
      const jsonParams = JSON.stringify(params);
      if (paramsUrlRef.current !== jsonParams && jsonParams === "[]") {
        requestApi(queryUrl, setReviews);
      }
      paramsUrlRef.current = jsonParams;
    }
    requestedRef.current = true;
  }, [searchParams]);

  useEffect(() => {
    if (filters.length > 0) {
      requestApi(createUrlWithParams(), setReviews);
    }
  }, [filters, createUrlWithParams]);
  useEffect(() => {
    if (page > 1) {
      requestApi(createUrlWithParams() + "&page=" + page, setReviews);
    }
  }, [page, createUrlWithParams]);

  return (
    <>
      <section className={s.reviewSection}>
        {reviews && reviews.total > 1 && (
          <h2 className={s.sectionHeader}>
            {24 * page - 24 + 1} - {24 * page - 24 + reviews.count} of{" "}
            {reviews.total} reviews
          </h2>
        )}
        {reviews?.interviews && (
          <ul className={s.reviewList}>
            {reviews.interviews.map((r: any, index: number) => (
              <li className={s.reviewCard} key={index}>
                <Card review={r} recommends={recommends} />
              </li>
            ))}
            {reviews?.interviews && reviews.interviews.length < 6 && (
              <li className={s.cardDisclaimer}>
                <FaceFrownIcon />
                <span>There is not much data yet</span>
                <Link to={"/new"}>Would you like to help fix it?</Link>
              </li>
            )}
          </ul>
        )}
        <div className={s.nav}>
          {!(page === 1) && (
            <div className={s.first}>
              <Button
                variant="primary"
                onClick={() => {
                  const newPage = page - 1;
                  setPage(newPage);
                  if (newPage === 1) {
                    requestApi(createUrlWithParams(), setReviews);
                  }
                }}
              >
                <ChevronDoubleLeftIcon />
                See newer reviews
              </Button>
            </div>
          )}
          {reviews && !(reviews.total < page * 24) && (
            <div className={s.last}>
              <Button
                variant="primary"
                onClick={() => {
                  setPage(page + 1);
                }}
              >
                See older reviews
                <ChevronDoubleRightIcon />
              </Button>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default List;
