import { animals, adjectives } from "./constants";

export const requestApi = (url, callback) => {
  try {
    window
      .fetch(url)
      .then((response) => response.json())
      .then((companies) => callback(companies));
  } catch (e) {
    throw new Error(e);
  }
};

const shuffle = (list) =>
  list
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);

export const generateUsername = () => {
  const randomAnimals = shuffle(animals);
  const randomAdjectives = shuffle(adjectives);
  return randomAdjectives[0] + " " + randomAnimals[0];
};

export const sendReview = (url, form, callback) => {
  (async () => {
    const rawResponse = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
    });
    const content = await rawResponse.json();

    if (callback && content.p_id) {
      callback(content.p_id);
    }
  })();
};

export const vote = (url) => {
  (async () => {
    const rawResponse = await fetch(url, {
      method: "POST",
    });
    const content = await rawResponse.json();
    console.info(content);
  })();
};
