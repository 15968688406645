import List from "../components/List";
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import Layout from "../components/Layout";

const FrontPage = () => {
  return (
    <Layout
      headerHref="/new"
      headerIcon={<PlusCircleIcon />}
      headerLabel="Add Review"
      heroSection
    >
      <List />
    </Layout>
  );
};

export default FrontPage;
