import { XCircleIcon } from "@heroicons/react/20/solid";
import Layout from "../components/Layout";
import { staticInfoTypes } from "../constants";
import { Link } from "react-router-dom";

const TextContent = (props: any) => {
  const { type } = props;
  // @ts-ignore
  const info = staticInfoTypes[type];
  return (
    <Layout
      headerHref="/"
      headerIcon={<XCircleIcon />}
      headerLabel="Cancel"
      headerButtonClass="cancelButton"
      heroSection={false}
      footerButton={true}
    >
      <h2>{info.title}</h2>
      <h3>{info.subtitle}</h3>
      <p dangerouslySetInnerHTML={{ __html: info.content }} />
      <p>
        Now, if you haven't yet, go{" "}
        <Link to={"/new"}>share your experience</Link> and share this website
        with whoever you think might be useful.
      </p>
      <p>Thank you.</p>
      <p>-A. and A.</p>
    </Layout>
  );
};

export default TextContent;
