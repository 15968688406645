import { BanknotesIcon, CurrencyDollarIcon } from "@heroicons/react/20/solid";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import s from "../styles/Stepper.module.scss";

export const Stepper = (props: any) => {
  const { stepCallback, stepMoneyCallback } = props;
  const [step, setStep] = useState(6);
  const [stepMoney, setStepMoney] = useState(4);
  const rangeRef = useRef<HTMLInputElement>(null);
  const stepMoneyRef = useRef<HTMLDivElement>(null);
  const bubbleRef = useRef<HTMLDivElement>(null);
  const [, setRangeWidthValue] = useState<number>();

  useLayoutEffect(() => {
    const { current: inputCurrent } = rangeRef;
    const { current: stepMoneyCurrent } = stepMoneyRef;
    const { current: bubbleCurrent } = bubbleRef;

    let rangeSectionWidth =
      inputCurrent && Math.abs(inputCurrent.offsetWidth / 9);

    let stepMoneyGap =
      rangeSectionWidth && Math.abs(rangeSectionWidth - 32 - 2);

    setRangeWidthValue(inputCurrent?.offsetWidth);

    stepMoneyCurrent?.style.setProperty("gap", `${stepMoneyGap}px`);
    stepMoneyCurrent?.style.setProperty(
      "max-width",
      `${inputCurrent?.offsetWidth}px`
    );
    let bubblePosition =
      inputCurrent &&
      Math.floor(((inputCurrent?.offsetWidth - 5) / 9) * (step - 1) - 4 - step);
    bubbleCurrent?.style.setProperty(
      "transform",
      `translateX(${bubblePosition && bubblePosition}px)`
    );

    const handleResize = () => {
      setRangeWidthValue(inputCurrent?.offsetWidth);
    };

    inputCurrent?.addEventListener("resize", handleResize);

    return () => {
      inputCurrent?.removeEventListener("resize", handleResize);
    };
  }, [step]);

  useEffect(() => stepCallback && stepCallback(step), [step, stepCallback]);
  useEffect(
    () => stepMoneyCallback && stepMoneyCallback(stepMoney),
    [stepMoney, stepMoneyCallback]
  );

  useEffect(() => {
    if (stepMoney > step) setStepMoney(step);
  }, [step, stepMoney]);

  const handleInput = (e: any, callback: any) => {
    const changedValue = e?.target?.value || e;
    callback(changedValue);
  };

  const renderStepMoney = () => {
    const inputs = [];
    for (let i = 0; i < step; i++) {
      inputs.push(
        <button
          key={`step-${i}`}
          onClick={(e) => handleInput(i + 1, setStepMoney)}
          className={`${i + 1 === stepMoney && s.activeMoney}`}
        >
          {i + 1 === stepMoney ? <CurrencyDollarIcon /> : <BanknotesIcon />}
        </button>
      );
    }
    return inputs;
  };

  return (
    <div className={s.rangeWrapper}>
      <div className={s.rangeInput}>
        <div className={s.rangeBubble}>
          <div ref={bubbleRef} className={s.bubble}>
            {step}
          </div>
        </div>
        <input
          ref={rangeRef}
          id="steps"
          data-tip={`${step}`}
          onChange={(e) => handleInput(e, setStep)}
          className={s.range}
          type="range"
          min="1"
          max="10"
          value={step}
          step="1"
        />
        <div className={s.stepMoney} ref={stepMoneyRef}>
          {renderStepMoney()}
        </div>
      </div>
    </div>
  );
};

export default Stepper;
