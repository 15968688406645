export const animals = [
  "moose",
  "bear",
  "cat",
  "horse",
  "leopard",
  "porcupine",
  "antelope",
  "deer",
  "eel",
  "alpaca",
  "llama",
  "bee",
  "caterpillar",
  "pelican",
  "zebra",
];
export const adjectives = [
  "happy",
  "tall",
  "heavy",
  "talky",
  "ancient",
  "panicky",
  "gentle",
  "glamorous",
  "itchy",
  "adventurous",
  "brave",
  "confident",
  "whimsical",
  "loud",
  "goofy",
];

export const company = [
  {
    name: "Facebook",
    value: "Facebook",
    domain: "facebook.com",
  },
  {
    name: "Apple",
    value: "Apple",
    domain: "apple.com",
  },
  {
    name: "Amazon",
    value: "Amazon",
    domain: "amazon.com",
  },
  {
    name: "Netflix",
    value: "Netflix",
    domain: "netflix.com",
  },
  {
    name: "Google",
    value: "Google",
    domain: "google.com",
  },
];

export const sectorList = [
  {
    group: "Technology",
    roles: [
      { name: "Software Engineer", value: "Software Engineer" },
      { name: "Site Reliability Engineer", value: "Site Reliability Engineer" },
      { name: "DevOps", value: "DevOps" },
      { name: "Quality Assurance", value: "Quality Assurance" },
      { name: "Systems Administrator", value: "Systems Administrator" },
      { name: "Product Designer", value: "Product Designer" },
      { name: "Product Manager", value: "Product Manager" },
      { name: "Technical Writer", value: "Technical Writer" },
    ],
  },
  {
    group: "Operations",
    roles: [
      { name: "Accountant", value: "Accountant" },
      { name: "Human Resources", value: "Human Resources" },
      { name: "Marketing", value: "Marketing" },
      { name: "Recruiter", value: "Recruiter" },
      { name: "Sales", value: "Sales" },
      { name: "Copywriter", value: "Copywriter" },
    ],
  },
  {
    group: "Business",
    roles: [
      { name: "Business Analyst", value: "Business Analyst" },
      { name: "Customer Service", value: "Customer Service" },
      { name: "Project Manager", value: "Project Manager" },
    ],
  },
];

export const seniorityList = [
  { name: "Entry", value: "Entry" },
  { name: "Mid", value: "Mid" },
  { name: "Senior", value: "Senior" },
  { name: "Other", value: "Other" },
];

export const staticInfoTypes = {
  about: {
    title: "About",
    subtitle:
      "InterviewedWith lets you explore and add job interview processes anonymously",
    content:
      "All the data provided by users is anonymous. " +
      "We store the email for future enquiries only, rest assure we will not send notifications or anything. " +
      "The email is properly stored as a hashed value which means we cannot read it by ourselves. " +
      "Also: we are not setting any cookie. " +
      'As of now the companies information is provided by <a href=//clearbit.com/ target="_blank">Clearbit</a> ' +
      "and we are not responsible for the data displayed through their API. " +
      "InterviewedWith was created by two people in 🇪🇺.",
  },
  legal: {
    title: "Legal",
    subtitle:
      "All info provided by users is anonymous and InterviewedWith is not responsible for the comments made by its users",
    content:
      "We encourage all users to be as aseptic and concise as possible when filling the optional free text field. " +
      "Regardless, we can not guarantee that will be always the case. That responsibility relies only on the users. " +
      "InterviewedWith can delete a review if it goes against the spirit of the website: " +
      "calling out people, making it easier to pinpoint anyone etc. " +
      "For more details on data management see the About section. For edits or removal enquiries see the Contact section.",
  },
  contact: {
    title: "Contact",
    subtitle: "Reach out through email",
    content:
      'For any kind of enquiry please ping us at <a href="mailto:hi@interviewedwith.com">hi@</a>. ' +
      "Please refer the URL of your review and the email provided when submitting it if you wish us to delete or edit said review. " +
      "You can include several reviews in a single mail. " +
      "Bear in mind we are (just only) humans, we might not respond as fast as you and I wished.",
  },
};
