import { useEffect, useState } from "react";
import { ReactComponent as LogoFull } from "../assets/Logo.svg";
import { ReactComponent as LogoMobile } from "../assets/LogoMobile.svg";
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import { Navigate } from "react-router-dom";
import s from "../styles/Footer.module.scss";
import { Link } from "react-router-dom";

interface Props {
  footerButton: boolean;
}

const Footer = ({ footerButton = true }: Props) => {
  const [isSpace, setSpace] = useState(window.innerWidth >= 500);
  const [addingReview, setAddingReview] = useState(false);

  const updateMedia = () => {
    setSpace(window.innerWidth >= 500);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const links = ["About", "Legal", "Contact"];

  return (
    <footer className={s.footer}>
      <div className={s.footerContent}>
        <div className={s.logoGrid}>
          <Link to={"/"} className={s.logo}>
            <h1>
              <span className="visually-hidden">InterviewedWith</span>
              {isSpace ? (
                <>
                  <LogoFull focusable="false" aria-hidden="true" />
                  <span className={s.beta}>Beta</span>
                </>
              ) : (
                <>
                  <LogoMobile focusable="false" aria-hidden="true" />
                  <span className={s.beta}>&beta;</span>
                </>
              )}
            </h1>
          </Link>
        </div>
        <div className={s.navGrid}>
          <nav className={s.footerNav}>
            {links.map((l) => (
              <Link key={l} to={"/" + l.toLowerCase()}>
                {l}
              </Link>
            ))}
          </nav>
        </div>
        <div className={s.buttonGrid}>
          {footerButton && (
            <button
              className={s.ctaButton}
              onClick={() => setAddingReview(true)}
            >
              <PlusCircleIcon className="" />
              Add Review
            </button>
          )}
          {addingReview && <Navigate to="/new" replace={true} />}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
