import { ReactNode, useEffect, useState } from "react";
import { ReactComponent as LogoFull } from "../assets/Logo.svg";
import { ReactComponent as LogoMobile } from "../assets/LogoMobile.svg";
import s from "../styles/Header.module.scss";
import { Link } from "react-router-dom";
import HeroSection from "./HeroSection";

interface Props {
  href: string;
  icon: ReactNode;
  label: string;
  buttonClass?: string;
  heroSection: boolean;
}

const Header = ({ href, buttonClass, icon, label, heroSection }: Props) => {
  const [isSpace, setSpace] = useState(window.innerWidth >= 500);
  /* const [addingReview, setAddingReview] = useState(false); */

  const updateMedia = () => {
    setSpace(window.innerWidth >= 500);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <header>
      <div className={s.header}>
        <Link to={"/"} className={s.logo}>
          <h1>
            <span className="visually-hidden">InterviewedWith</span>
            {isSpace ? (
              <>
                <LogoFull focusable="false" aria-hidden="true" />
                <span className={s.beta}>Beta</span>
              </>
            ) : (
              <>
                <LogoMobile focusable="false" aria-hidden="true" />
                <span className={s.beta}>&beta;</span>
              </>
            )}
          </h1>
        </Link>
        <Link
          to={href}
          className={`${s.ctaButton} ${buttonClass ? s[buttonClass] : ""}`}
        >
          {icon}
          {label}
        </Link>
      </div>
      {heroSection && <HeroSection />}
    </header>
  );
};

export default Header;
