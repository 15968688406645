import s from "../styles/Banner.module.scss";
import { ReactComponent as FormMock } from "../assets/formMock.svg";
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <div className={s.bannerWrapper}>
      <div className={s.bannerContent}>
        <div className={s.bannerText}>
          <h2>
            Add your review today
            <br />
            Help others tomorrow
          </h2>
          <p>It takes less than a minute to leave a review. </p>
          <Link to="/new" className={s.ctaButton}>
            <PlusCircleIcon />
            Add Review
          </Link>
        </div>

        <div className={s.bannerImg}>
          <FormMock />
        </div>
      </div>
    </div>
  );
};

export default Banner;
