// @ts-ignore
import { useReducer, useState, useEffect } from "react";
import { generateUsername, sendReview } from "../helpers";
import s from "../styles/ReviewForm.module.scss";
import {
  ArrowRightCircleIcon,
  CheckCircleIcon,
  BuildingOfficeIcon,
  ArrowPathIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  UserCircleIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import IWCombobox from "./IWCombobox";
import { company, sectorList, seniorityList } from "../constants";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import Stepper from "./Stepper";
import { SparklesIcon } from "@heroicons/react/24/outline";

const API_URL = process.env.REACT_APP_API_URL;
const queryUrl = API_URL + "/latestreviews";

type State = {
  company: string;
  is_contacted_by_company: boolean;
  sector: string;
  seniority: string;
  position: string;
  is_finished: boolean;
  steps_done: number;
  steps_money: number;
  body: string;
  is_recommended: boolean;
  email: string;
  localization: string;
  username: string;
  company_domain: string;
};

type Action =
  | { type: "company"; value: string }
  | { type: "is_contacted_by_company"; value: boolean }
  | { type: "sector"; value: string }
  | { type: "seniority"; value: string }
  | { type: "position"; value: string }
  | { type: "is_finished"; value: boolean }
  | { type: "steps_done"; value: number }
  | { type: "steps_money"; value: number }
  | { type: "body"; value: string }
  | { type: "is_recommended"; value: boolean }
  | { type: "email"; value: string }
  | { type: "localization"; value: string }
  | { type: "username"; value: string }
  | { type: "company_domain"; value: string };

const initialState = {
  company: "",
  is_contacted_by_company: true,
  sector: "",
  seniority: "",
  position: "",
  is_finished: true,
  steps_done: 6,
  steps_money: 4,
  body: "",
  is_recommended: true,
  email: "",
  localization: "",
  username: "",
  company_domain: "",
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "company":
      return { ...state, company: action.value };
    case "is_contacted_by_company":
      return { ...state, is_contacted_by_company: action.value };
    case "sector":
      return { ...state, sector: action.value };
    case "seniority":
      return { ...state, seniority: action.value };
    case "position":
      return { ...state, position: action.value };
    case "is_finished":
      return { ...state, is_finished: action.value };
    case "steps_done":
      return { ...state, steps_done: action.value };
    case "steps_money":
      return { ...state, steps_money: action.value };
    case "body":
      return { ...state, body: action.value };
    case "is_recommended":
      return { ...state, is_recommended: action.value };
    case "email":
      return { ...state, email: action.value };
    case "localization":
      return { ...state, localization: action.value };
    case "username":
      return { ...state, username: action.value };
    case "company_domain":
      return { ...state, company_domain: action.value };
    default:
      throw new Error();
  }
}

const ReviewForm = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [bodyLength, setBodyLength] = useState(500);
  const [firstSectionFilled, setFirstSectionFilled] = useState(false);
  // @ts-ignore
  const canContinue = state.company && state.sector && state.seniority;
  const contacted = state.is_contacted_by_company === true;
  const navigate = useNavigate();
  const handlePostReview = (p_id: string) => {
    navigate({
      pathname: `/${p_id}`,
    });
  };
  useEffect(() => {
    document.title = `Share a job interview review`;
  }, []);
  const canSendForm = state.username.length > 0 && state.body.length > 0;

  return (
    <>
      <div className={s.pageHeader}>
        <div className={s.title}>
          <h2 className={s.pageTitle}>Add your experience</h2>
          <span className={s.pageSubtitle}>Don't worry, it's anonymous</span>
        </div>
        <div className={s.pageActions}>
          <Button variant="tertiary" color="brand" onClick={() => {}}>
            <ArrowPathIcon /> Restart
          </Button>
        </div>
      </div>
      <div className={s.pageBody}>
        <fieldset>
          <div className={s.sectionHeader}>
            <h3 className={s.sectionTitle}>Company & Title</h3>
            <span className={s.sectionSubtitle}>
              Provide basic info about the company and the position you applied
              for. Also, we would like to know who broke the ice.
            </span>
          </div>
          <div className={s.row}>
            <div className={s.formInput}>
              <IWCombobox
                label="Company"
                filterType="company"
                items={company}
                onChangeCallback={(value: any) => {
                  if (
                    value?.name !== state.company ||
                    value?.domain !== state.company_domain
                  ) {
                    // @ts-ignore
                    dispatch({ type: "company", value: value?.name });
                    // @ts-ignore
                    dispatch({ type: "company_domain", value: value?.domain });
                  }
                }}
              />
            </div>
            <div className={s.formInput}>
              <label className={s.label}>Who started the conversation</label>
              <div className={s.buttonGroup}>
                <button
                  className={`${contacted ? s.selectionActive : ""} ${
                    s.selectionButton
                  }`}
                  onClick={() =>
                    // @ts-ignore
                    dispatch({
                      type: "is_contacted_by_company",
                      value: true,
                    })
                  }
                >
                  <BuildingOfficeIcon />{" "}
                  {state.company ? state.company : "Company"}
                </button>
                <button
                  className={`${contacted ? "" : s.selectionActive} ${
                    s.selectionButton
                  }`}
                  onClick={() =>
                    // @ts-ignore
                    dispatch({
                      type: "is_contacted_by_company",
                      value: false,
                    })
                  }
                >
                  <UserCircleIcon /> Me
                </button>
              </div>
            </div>
          </div>
          <div className={s.row}>
            <div className={s.formInput}>
              <IWCombobox
                label="Role"
                filterType="sector"
                items={sectorList}
                onChangeCallback={(value: string) => {
                  if (value !== state.sector) {
                    // @ts-ignore
                    dispatch({ type: "sector", value });
                  }
                }}
              />
            </div>
            <div className={s.formInput}>
              <IWCombobox
                label="Seniority"
                filterType="seniority"
                items={seniorityList}
                onChangeCallback={(value: string) => {
                  if (value !== state.seniority) {
                    // @ts-ignore
                    dispatch({ type: "seniority", value });
                  }
                }}
              />
            </div>
          </div>
          <div className={`${s.row} ${s.rowFull}`}>
            <div className={s.formInput}>
              <label className={s.label}>
                Title of position <span className={s.optional}>Optional</span>
              </label>
              <input
                className={s.input}
                placeholder={"Ninja Web Sourcerer"}
                type={"text"}
                onChange={(e) =>
                  // @ts-ignore
                  dispatch({ type: "position", value: e?.target?.value })
                }
              />
            </div>
          </div>

          {!firstSectionFilled && (
            <div className={`${s.row} ${s.rowFull}`}>
              <Button
                disabled={!canContinue}
                variant="primary"
                className={!canContinue ? "buttonDisabled" : ""}
                onClick={() => setFirstSectionFilled(true)}
              >
                <ArrowRightCircleIcon />
                One more step
              </Button>
            </div>
          )}
        </fieldset>
        {firstSectionFilled && (
          <fieldset>
            <div className={s.sectionHeader}>
              <h3 className={s.sectionTitle}>Application process</h3>
              <span className={s.sectionSubtitle}>
                Share valuable details about the number of steps in the process
                and any other information you think it's important to know.
              </span>
            </div>
            <div className={`${s.row} ${s.rowFull}`}>
              <div className={`${s.formInput} ${s.formInputHorz}`}>
                <label className={s.label}>
                  Is the application process completed?
                </label>

                <div className={s.buttonGroup}>
                  <button
                    className={`${state.is_finished ? s.selectionActive : ""} ${
                      s.selectionButton
                    }`}
                    onClick={() =>
                      // @ts-ignore
                      dispatch({
                        type: "is_finished",
                        value: true,
                      })
                    }
                  >
                    <CheckCircleIcon /> Yes
                  </button>
                  <button
                    className={`${state.is_finished ? "" : s.selectionActive} ${
                      s.selectionButton
                    }`}
                    onClick={() =>
                      // @ts-ignore
                      dispatch({
                        type: "is_finished",
                        value: false,
                      })
                    }
                  >
                    <XCircleIcon /> No
                  </button>
                </div>
              </div>
            </div>
            <div className={`${s.row} ${s.rowFull}`}>
              <div className={s.formInput}>
                <label className={s.label}>
                  How many steps did you went through?
                  <span className={s.optional}>
                    Use the slider to select the number of steps in the process.
                  </span>
                </label>
                <Stepper
                  stepCallback={(value: any) => {
                    if (value !== state.steps_done) {
                      // @ts-ignore
                      dispatch({ type: "steps_done", value });
                    }
                  }}
                  stepMoneyCallback={(value: any) => {
                    if (value !== state.steps_money) {
                      // @ts-ignore
                      dispatch({ type: "steps_money", value });
                    }
                  }}
                />
                <span className={s.optional}>
                  Select at which step you discussed salary{" "}
                  <svg
                    width="29"
                    height="19"
                    viewBox="0 0 29 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.89292 16.4952C1.33796 16.5543 0.936025 17.0521 0.995163 17.6071C1.0543 18.162 1.55212 18.564 2.10708 18.5048L1.89292 16.4952ZM23.8692 0.984581C23.5845 0.504536 22.9646 0.346143 22.4846 0.630801L14.6618 5.26957C14.1817 5.55423 14.0234 6.17415 14.308 6.65419C14.5927 7.13424 15.2126 7.29263 15.6926 7.00797L22.6462 2.88462L26.7696 9.83821C27.0542 10.3183 27.6741 10.4766 28.1542 10.192C28.6342 9.90733 28.7926 9.28742 28.508 8.80737L23.8692 0.984581ZM11.938 16.441L11.8309 15.4361L11.938 16.441ZM21.2393 8.39108L20.2602 8.14092L21.2393 8.39108ZM2.10708 18.5048L12.0451 17.4458L11.8309 15.4361L1.89292 16.4952L2.10708 18.5048ZM22.2184 8.64124L23.9791 1.75016L22.0209 1.24984L20.2602 8.14092L22.2184 8.64124ZM12.0451 17.4458C16.9423 16.9239 20.9992 13.4129 22.2184 8.64124L20.2602 8.14092C19.25 12.0946 15.8886 15.0037 11.8309 15.4361L12.0451 17.4458Z"
                      fill="#94A3B8"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div className={`${s.row} ${s.rowFull}`}>
              <div className={s.formInput}>
                <label className={s.label}>
                  Review{" "}
                  <span className={s.optional}>{bodyLength} chars left</span>
                </label>
                <textarea
                  className={`${s.input} ${s.textarea}`}
                  maxLength={500}
                  placeholder={
                    "Add any other information you might find interesting as location or team"
                  }
                  onChange={(e) => {
                    // @ts-ignore
                    dispatch({ type: "body", value: e?.target?.value });
                    setBodyLength(500 - e?.target?.value?.length);
                  }}
                ></textarea>
              </div>
            </div>
            <div className={`${s.row} ${s.rowFull}`}>
              <div className={`${s.formInput} ${s.formInputHorz}`}>
                <label className={s.label}>
                  Do you recommend people to apply?
                </label>
                <div className={s.buttonGroup}>
                  <button
                    className={`${
                      state.is_recommended ? s.selectionActive : ""
                    } ${s.selectionButton}`}
                    onClick={() =>
                      // @ts-ignore
                      dispatch({
                        type: "is_recommended",
                        value: true,
                      })
                    }
                  >
                    <HandThumbUpIcon /> Yes
                  </button>
                  <button
                    className={`${
                      state.is_recommended ? "" : s.selectionActive
                    } ${s.selectionButton}`}
                    onClick={() =>
                      // @ts-ignore
                      dispatch({
                        type: "is_recommended",
                        value: false,
                      })
                    }
                  >
                    <HandThumbDownIcon /> No
                  </button>
                </div>
              </div>
            </div>
            <div className={`${s.row} ${s.rowFull}`}>
              <div className={s.formInput}>
                <label className={s.label}>
                  Email{" "}
                  <span className={s.optional}>
                    Optional, it will only be used to check your future
                    enquiries
                  </span>
                </label>
                <input
                  className={s.input}
                  type={"email"}
                  onChange={(e) =>
                    // @ts-ignore
                    dispatch({ type: "email", value: e?.target?.value })
                  }
                />
              </div>
            </div>

            <div className={`${s.row} ${s.rowFull}`}>
              <div className={`${s.formInput} ${s.formInputHorz}`}>
                <label className={s.label}>
                  Username{" "}
                  <span className={s.optional}>Not important, just funny!</span>
                </label>
                <div className={s.usernameField}>
                  <Button
                    variant="tertiary"
                    color="brand"
                    size="sm"
                    onClick={() =>
                      // @ts-ignore
                      dispatch({ type: "username", value: generateUsername() })
                    }
                  >
                    <SparklesIcon />
                    Randomize
                  </Button>
                  <span>{state.username}</span>
                </div>
              </div>
            </div>

            <div className={`${s.row} ${s.rowFull}`}>
              <Button
                variant="primary"
                className={!canSendForm ? "buttonDisabled" : ""}
                disabled={!canSendForm}
                onClick={() => sendReview(queryUrl, state, handlePostReview)}
              >
                <CheckCircleIcon /> Submit Review
              </Button>
            </div>
          </fieldset>
        )}
      </div>
    </>
  );
};

export default ReviewForm;
