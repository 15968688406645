import { PlusCircleIcon } from "@heroicons/react/20/solid";
import Layout from "../components/Layout";
import { useEffect, useRef, useState } from "react";
import { requestApi } from "../helpers";
import s from "../styles/Frontpage.module.scss";
import { Link } from "react-router-dom";
import CardList from "../components/CardList";
import Banner from "../components/Banner";
import { IInterview, IRecommends, IReviews } from "../ts/interfaces";

const API_URL = process.env.REACT_APP_API_URL;
const latestReviewsUrl = API_URL + "/latestreviews";
const topCompaniesUrl = API_URL + "/top/companies";
const topHelpfulUrl = API_URL + "/top/interviews";
const recommendUrl = API_URL + "/recommends";

const FrontPage = () => {
  const [latestReviewsResp, setLatestReviews] = useState<IReviews | null>();
  const [topCompaniesResp, setTopCompanies] = useState<any | null>({});
  const [topHelpfulResp, setTopHelpful] = useState<any | null>({});
  const [recommends, setRecommends] = useState<IRecommends | null>();
  const requestedRef = useRef(false);

  const latestReviews = latestReviewsResp?.interviews as IInterview[];
  const companies = topCompaniesResp?.top_companies;
  const topHelpful = topHelpfulResp?.top_interviews as IInterview[];

  useEffect(() => {
    if (!requestedRef.current) {
      requestApi(latestReviewsUrl, setLatestReviews);
      requestApi(topCompaniesUrl, setTopCompanies);
      requestApi(topHelpfulUrl, setTopHelpful);
      requestApi(recommendUrl, setRecommends);
      requestedRef.current = true;
    }
  }, []);
  useEffect(() => {
    document.title = `Reviews of job interviews with tech companies`;
  }, []);

  return (
    <Layout
      headerHref="/new"
      headerIcon={<PlusCircleIcon />}
      headerLabel="Add Review"
      heroSection
    >
      <CardList
        title="Latest Reviews"
        cardArr={latestReviews}
        recommends={recommends}
        // reverse
      />
      <Banner />
      <section className={s.section}>
        <h2 className={s.sectionHeader}>Top Recommended Companies</h2>
        <ul className={s.companyList}>
          {companies?.map((company: any, index: number) => (
            <li key={index} className={s.companyItem}>
              <Link to={`/?company=${company.name}`}>
                <img
                  alt={`Logo of ${company.domain}`}
                  src={`https://logo.clearbit.com/${company.domain}`}
                />
              </Link>
            </li>
          ))}
        </ul>
      </section>
      <CardList
        title="Most helpful reviews"
        cardArr={topHelpful}
        recommends={recommends}
        reverse
      />
    </Layout>
  );
};

export default FrontPage;
