import { useState, useEffect, ReactNode, useRef } from "react";
import {
  useParams,
  Link,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import { requestApi, vote } from "../helpers";
import Button from "../components/Button";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  ArrowSmallLeftIcon,
  PlusCircleIcon,
} from "@heroicons/react/20/solid";
import Layout from "../components/Layout";
import s from "../styles/Detail.module.scss";
import { HandThumbUpIcon } from "@heroicons/react/24/solid";
import { IInterviewDetail, ILateral } from "../ts/interfaces";

const API_URL = process.env.REACT_APP_API_URL;

const Detail = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [review, setReview] = useState<IInterviewDetail | null>();
  const reviewRef = useRef(false);
  const lateral = useRef<ILateral | null>();
  const interview = review && review.interview;
  let contacted: ReactNode;
  const [voted, setVoted] = useState(
    localStorage.getItem("int_with" + id) === "true"
  );

  const queryUrl = API_URL + "/interviews/" + id;
  const voteUrl = API_URL + "/interviews/vote/" + id;

  let lateralUrl = "";
  if (searchParams) {
    lateralUrl = API_URL + `${"/lateral/" + id}?${searchParams}`;
  } else {
    lateralUrl = API_URL + `${"/lateral/" + id}`;
  }

  useEffect(() => {
    if (!reviewRef.current) {
      requestApi(queryUrl, setReview);
      requestApi(lateralUrl, (payload: ILateral | null | undefined) => {
        lateral.current = payload;
      });
      reviewRef.current = true;
    }
  }, [queryUrl, lateralUrl, searchParams]);

  useEffect(() => {
    document.title = `Interview with ${interview?.company}`;
  }, [interview]);

  useEffect(() => {
    reviewRef.current = !reviewRef.current;
  }, [location]);

  const handleVote = () => {
    vote(voteUrl);
    localStorage.setItem("int_with" + id, "true");
    setVoted(true);
  };

  if (interview && interview.is_contacted_by_company) {
    contacted = (
      <>
        <Link to={`/companyInfo?company=${interview.company}`}>
          <strong>{interview.company}</strong>{" "}
        </Link>{" "}
        contacted <strong>{interview.username}</strong> for{" "}
      </>
    );
  } else if (interview && !interview.is_contacted_by_company) {
    contacted = (
      <>
        <span>
          <strong>{interview.username}</strong>{" "}
        </span>{" "}
        contacted{" "}
        <Link to={`/companyInfo?company=${interview.company}`}>
          <strong>{interview.company}</strong>
        </Link>{" "}
        for{" "}
      </>
    );
  }

  if (lateral.current) {
    console.log("lateral current", lateral.current);
  }

  return (
    <Layout
      headerHref="/new"
      headerIcon={<PlusCircleIcon />}
      headerLabel="Add Review"
      heroSection={false}
    >
      <Link
        to={
          searchParams
            ? `/interviews?${searchParams}`
            : "/interviews?company=&sector=&seniority="
        }
        className={s.button}
      >
        <ArrowSmallLeftIcon /> Go to Search
      </Link>
      {interview && (
        <>
          <div
            className={`${s.detail} ${
              interview.is_recommended ? "" : s.cardNotRecommended
            }`}
          >
            <p className={s.date}>
              {new Date(interview.date).toLocaleDateString(
                navigator.languages[0]
              )}
            </p>
            <div className={s.detailHeader}>
              <Link
                to={`/companyInfo?company=${interview.company}&sector=&seniority=`}
              >
                <img
                  alt={`Logo of ${interview.company}`}
                  src={`https://logo.clearbit.com/${interview.company_domain}`}
                />
              </Link>
              <p>
                {contacted}
                <span>
                  <strong>
                    {interview.seniority} {interview.sector}
                  </strong>
                </span>
                {interview.position && (
                  <>
                    , they called it{" "}
                    <span>
                      <strong>{interview.position}</strong>
                    </span>
                    .
                  </>
                )}
              </p>
            </div>

            <div className={s.detailBody}>
              <p>
                The{" "}
                <strong>
                  process ended
                  {!interview.is_finished && (
                    <strong>, but not completed,</strong>
                  )}{" "}
                  at step {interview.steps_done}
                </strong>{" "}
                {interview.steps_money > 0 && (
                  <>
                    and they{" "}
                    <strong>
                      discussed money at step&nbsp;{interview.steps_money}
                    </strong>
                  </>
                )}
                .
              </p>
              <p>
                <span className="capitalize">{interview.username}</span>{" "}
                <strong>
                  {interview.is_recommended ? (
                    <span className={s.recommends}>recommends</span>
                  ) : (
                    <span className={s.notRecommends}>does not recommend</span>
                  )}{" "}
                  applying.
                </strong>
              </p>
              {interview.body && (
                <>
                  <p>
                    This is what <strong>{interview.username}</strong> had to
                    say:
                  </p>
                  <blockquote>{interview.body}</blockquote>
                </>
              )}
            </div>
            <div className={s.detailFooter}>
              <Button
                variant="secondary"
                color="brand"
                onClick={() => handleVote()}
                disabled={voted}
                className={voted ? "buttonDisabled" : ""}
              >
                <HandThumbUpIcon />
                {!voted && interview.votes < 1
                  ? "Be the first to mark as helpful!"
                  : `Helpful for ${
                      voted
                        ? `${interview.votes} ${
                            interview.votes === 1 ? "person" : "people"
                          } including you!`
                        : `${interview.votes} people, be one of them!`
                    }`}
              </Button>
            </div>
          </div>
          <nav className={s.nav}>
            <div className={s.prev}>
              {/* {lateral.current?.prevpid !== "N/A" && (
                <PrevInterviewLink
                  //@ts-ignore
                  p_id={lateral.current?.prevpid}
                  searchParams={searchParams}
                />
              )} */}
            </div>
            <div className={s.navExplore}>
              <span className={s.exploreTitle}>Explore more</span>
              <Link
                className={s.explorePill}
                to={`/companyInfo?company=${interview.company}`}
              >
                <span>{interview.company}</span> interviews
              </Link>
              <Link
                className={s.explorePill}
                to={`/interviews?company=&sector=${interview.sector}&seniority=${interview.seniority}`}
              >
                <span>
                  {interview.seniority} {interview.sector}
                </span>{" "}
                interviews
              </Link>
            </div>
            <div className={s.next}>
              {/* {lateral.current?.nextpid !== "N/A" && (
                <NextInterviewLink
                  //@ts-ignore
                  p_id={lateral.current?.nextpid}
                  searchParams={searchParams}
                />
              )} */}
            </div>
          </nav>
        </>
      )}
    </Layout>
  );
};

interface IInterviewLink {
  p_id: string;
  searchParams: URLSearchParams;
}

const PrevInterviewLink = ({ p_id, searchParams }: IInterviewLink) => {
  return (
    <Link
      to={
        searchParams
          ? `/${p_id}?${searchParams}`
          : `/${p_id}?company=&sector=&seniority=`
      }
      className={s.navButton}
    >
      <div className={s.navArrow}>
        <ArrowLongLeftIcon />
        <span>Previous Review</span>
      </div>
    </Link>
  );
};

const NextInterviewLink = ({ p_id, searchParams }: IInterviewLink) => {
  return (
    <Link
      to={
        searchParams
          ? `/${p_id}?${searchParams}`
          : `/${p_id}?company=&sector=&seniority=`
      }
      className={s.navButton}
    >
      <div className={s.navArrow}>
        <span>Next Review</span>
        <ArrowLongRightIcon />
      </div>
    </Link>
  );
};

export default Detail;
