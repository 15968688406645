import { Fragment, ReactNode, useEffect, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, XMarkIcon } from "@heroicons/react/20/solid";
import s from "../styles/IWCombobox.module.scss";
import { requestApi } from "../helpers";
import {
  // createSearchParams,
  // useNavigate,
  useSearchParams,
} from "react-router-dom";

type Props = {
  label?: string;
  multiple?: boolean;
  button?: boolean;
  icon?: ReactNode;
  filterType?: string;
  items?: object[];
  onChangeCallback?: Function;
};

const COMPANIES_API_URL =
  "https://autocomplete.clearbit.com/v1/companies/suggest?query=";

// https://headlessui.com/react/combobox
const IWCombobox: React.FC<Props> = ({
  label,
  multiple,
  button = false,
  icon,
  filterType = "",
  items = [{ name: "", value: "" }],
  onChangeCallback = () => {},
  ...rest
}) => {
  const [selectedItem, setSelectedItem] = useState({ name: "" });
  const [newItems, setNewItems] = useState(items);
  const [query, setQuery] = useState<any | null>([]);
  const [searchParams] = useSearchParams();
  // const navigate = useNavigate();
  const isCompany = filterType === "company";
  const isRole = filterType === "sector";

  useEffect(() => {
    onChangeCallback &&
      onChangeCallback(isCompany ? selectedItem : selectedItem?.name);
    if (window.location.pathname !== "/") return;
    // @ts-ignore
    const currentParams = Object.fromEntries([...searchParams]);
    if (selectedItem?.name) {
      currentParams[filterType] = selectedItem.name;
    } else {
      delete currentParams[filterType];
    }
    // we probably don't need this anymore (check HeroSection) keeping at hand just in case
    // navigate({
    //   pathname: "/",
    //   search: `?${createSearchParams(currentParams)}`,
    // });
  }, [
    selectedItem,
    filterType,
    // navigate,
    searchParams,
    isCompany,
    onChangeCallback,
  ]);

  useEffect(() => {
    // @ts-ignore
    const currentParams = [...searchParams];
    if (
      currentParams.length > 0 &&
      currentParams.flat().indexOf(filterType) !== -1
    ) {
      const paramForFilter = currentParams.find(
        (p) => p.indexOf(filterType) !== -1
      );
      setSelectedItem({ name: paramForFilter[1] });
    }
  }, [searchParams, filterType]);

  const handleChange = (event: any = {}) => {
    if (isCompany && event.target) {
      const queryUrl = COMPANIES_API_URL + event.target.value;
      const handleRequestApi = (response: any) => {
        // @ts-ignore
        const filtered = [...new Set(response.map((c) => c.name))].map(
          (co) =>
            response.find(
              (com: { name: string; logo: string }) =>
                com.name === co && com.logo
            ) ||
            response.find(
              (com: { name: string; logo: string }) => com.name === co
            )
        );
        setQuery(filtered);
      };
      requestApi(queryUrl, handleRequestApi);
    } else {
      const search = event?.target?.value;
      if (search === "") {
        setNewItems(items);
      }
      if (isRole) {
        setNewItems(
          items.map((i: any) => ({
            group: i.group,
            roles: i.roles.filter((f: any) =>
              f.name.toLowerCase().includes(search.toLowerCase())
            ),
          }))
        );
      } else {
        setNewItems(
          items.filter((f: any) =>
            f.name.toLowerCase().includes(search.toLowerCase())
          )
        );
      }
    }
  };

  return (
    <div className={s.comboboxSet}>
      <Combobox value={selectedItem} onChange={setSelectedItem} nullable>
        {label && <Combobox.Label className={s.label}>{label}</Combobox.Label>}
        <div className={s.inputArea}>
          <Combobox.Button as="div">
            <Combobox.Input
              className={s.input}
              placeholder={`Search for a ${filterType}…`}
              onChange={(event) => handleChange(event)}
              displayValue={(company: any) => company?.name}
            />
          </Combobox.Button>
          {selectedItem?.name.length > 0 && (
            <span
              className={s.button}
              onClick={() => {
                setSelectedItem({ name: "" });
                setNewItems(items);
              }}
            >
              <XMarkIcon className={s.icon} aria-hidden="true" />
            </span>
          )}
        </div>
        <Transition
          as={Fragment}
          leave={s.leave}
          leaveFrom={s.leaveFrom}
          leaveTo={s.leaveTo}
          afterLeave={() => setQuery("")}
        >
          <Combobox.Options className={s.comboboxOptions}>
            {isCompany &&
              query.length === 0 &&
              items.map((company: any) => (
                <Combobox.Option
                  key={company.name}
                  value={company}
                  as={Fragment}
                >
                  {({ active, selected }) => (
                    <li
                      className={`${s.listItem} ${s.company} ${
                        active ? s.active : ""
                      }`}
                    >
                      <div className={s.imgWrapper}>
                        <img
                          src={`https://logo.clearbit.com/${company.domain}`}
                          alt={company.domain}
                        />{" "}
                      </div>
                      <span className={`${selected ? s.selected : ""}`}>
                        {company.name} {company.domain && `(${company.domain})`}
                      </span>
                      {selected ? (
                        <span
                          className={`${s.selectIcon} ${
                            active ? s.active : ""
                          }`}
                        >
                          <CheckIcon />
                        </span>
                      ) : null}
                    </li>
                  )}
                </Combobox.Option>
              ))}
            {isCompany &&
              query &&
              query.length > 1 &&
              query.map((company: any) => (
                <Combobox.Option
                  key={company.name}
                  value={company}
                  as={Fragment}
                >
                  {({ active, selected }) => (
                    <li
                      className={`${s.listItem} ${s.company} ${
                        active ? s.active : ""
                      }`}
                    >
                      <div className={s.imgWrapper}>
                        <img src={company.logo} alt={company.domain} />{" "}
                      </div>
                      <span className={`${selected ? s.selected : ""}`}>
                        {company.name} {company.domain && `(${company.domain})`}
                      </span>
                      {selected ? (
                        <span
                          className={`${s.selectIcon} ${
                            active ? s.active : ""
                          }`}
                        >
                          <CheckIcon />
                        </span>
                      ) : null}
                    </li>
                  )}
                </Combobox.Option>
              ))}
            {isRole &&
              newItems.map((item: any) => (
                <Fragment key={item.group}>
                  <Combobox.Option value={item} as={Fragment} disabled>
                    {({ active, selected }) => (
                      <li
                        className={`${s.groupItem} ${active ? s.active : ""}`}
                      >
                        {item.group}
                        {selected ? (
                          <span
                            className={`${s.selectIcon} ${
                              active ? s.active : ""
                            }`}
                          >
                            <CheckIcon />
                          </span>
                        ) : null}
                      </li>
                    )}
                  </Combobox.Option>
                  {item.roles &&
                    item.roles.map((role: any) => (
                      <Combobox.Option
                        key={role.name}
                        value={role}
                        as={Fragment}
                      >
                        {({ active, selected }) => (
                          <li
                            className={`${s.listItem} ${
                              active ? s.active : ""
                            }`}
                          >
                            {role.name}
                            {selected ? (
                              <span
                                className={`${s.selectIcon} ${
                                  active ? s.active : ""
                                }`}
                              >
                                <CheckIcon />
                              </span>
                            ) : null}
                          </li>
                        )}
                      </Combobox.Option>
                    ))}
                </Fragment>
              ))}
            {!isCompany &&
              !isRole &&
              newItems.map((item: any) => (
                <Combobox.Option key={item.name} value={item} as={Fragment}>
                  {({ active, selected }) => (
                    <li className={`${s.listItem} ${active ? s.active : ""}`}>
                      {item.name}
                      {selected ? (
                        <span
                          className={`${s.selectIcon} ${
                            active ? s.active : ""
                          }`}
                        >
                          <CheckIcon />
                        </span>
                      ) : null}
                    </li>
                  )}
                </Combobox.Option>
              ))}
          </Combobox.Options>
        </Transition>
      </Combobox>
      {isCompany && (
        <span>
          Service provided by <a href="///clearbit.com">Clearbit</a>
        </span>
      )}
    </div>
  );
};

export default IWCombobox;
