import s from "../styles/HeroSection.module.scss";
import Button from "./Button";
import { company, sectorList, seniorityList } from "../constants";
import IWCombobox from "./IWCombobox";
import { MagnifyingGlassCircleIcon } from "@heroicons/react/20/solid";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { createSearchParams, useNavigate } from "react-router-dom";

const HeroSection = () => {
  const [selectedCompany, setSelectedCompany] = useState({ name: "" });
  const [selectedSector, setSelectedSector] = useState("");
  const [selectedSeniority, setSelectedSeniority] = useState("");
  const highlightWordRef = useRef<HTMLDivElement>(null);
  const highlightRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const isFrontPage = window.location.pathname === "/";

  useLayoutEffect(() => {
    const { current: highlightWordCurrent } = highlightWordRef;
    const { current: highlightCurrent } = highlightRef;
    let dimensions = {
      height: 0,
      left: 0,
      top: 0,
    };

    const handleResize = () => {
      if (highlightWordCurrent) {
        dimensions = {
          height: highlightWordCurrent.offsetHeight,
          left: highlightWordCurrent.offsetLeft,
          top: highlightWordCurrent.offsetTop,
        };
      }
      highlightCurrent?.style.setProperty(
        "--highlight-height",
        `${dimensions.height}px`
      );
      highlightCurrent?.style.setProperty(
        "--highlight-left",
        `${dimensions.left}px`
      );
      highlightCurrent?.style.setProperty(
        "--highlight-top",
        `${dimensions.top}px`
      );
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const triggerSearch = useCallback(() => {
    const params = {
      company: selectedCompany ? selectedCompany.name : "",
      sector: selectedSector,
      seniority: selectedSeniority,
    };
    navigate({
      pathname: `${params.company !== "" ? "/companyInfo" : "/interviews"}`,
      search: `?${createSearchParams(params)}`,
    });
  }, [navigate, selectedCompany, selectedSector, selectedSeniority]);

  useEffect(() => {
    if (isFrontPage && selectedCompany?.name !== "") {
      triggerSearch();
    }
  }, [selectedCompany, isFrontPage, triggerSearch]);

  return (
    <div className={`${s.herosection} ${!isFrontPage && s.heroSectionSearch} `}>
      {isFrontPage && (
        <div className={s.heroTitle}>
          <h2>
            See&nbsp;
            <div ref={highlightWordRef} className={s.highlightWord}>
              what's&nbsp;coming
              <div ref={highlightRef} className={s.highlight}></div>{" "}
            </div>
            <br />
            in your next&nbsp;interview
          </h2>
          <div className={s.quote}>
            <p>
              ”The best place to read first hand reviews about
              job&nbsp;interviews”
            </p>
            <span>A friend I showed the website to</span>
          </div>
        </div>
      )}
      <div className={s.heroSearch}>
        <div className={`${s.filters} ${!isFrontPage && s.filtersRow}`}>
          {isFrontPage ? (
            <IWCombobox
              filterType="company"
              icon={<MagnifyingGlassIcon />}
              items={company}
              onChangeCallback={setSelectedCompany}
            />
          ) : (
            <>
              <IWCombobox
                label="Company"
                filterType="company"
                items={company}
                onChangeCallback={setSelectedCompany}
              />
              <IWCombobox
                label="Role"
                filterType="sector"
                items={sectorList}
                onChangeCallback={setSelectedSector}
              />
              <IWCombobox
                label="Seniority"
                filterType="seniority"
                items={seniorityList}
                onChangeCallback={setSelectedSeniority}
              />
              <Button variant="primary" onClick={triggerSearch}>
                <MagnifyingGlassCircleIcon />
                Search
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
