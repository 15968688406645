import s from "../styles/Card.module.scss";
import { Link, useSearchParams } from "react-router-dom";

const Card = ({ recommends, review }: any) => {
  const [searchParams] = useSearchParams();
  const reviewCount = recommends?.recommends?.total_results?.find(
    (f: any) => f[review.company]
  )[review.company];
  return (
    <div
      className={`${s.card} ${
        review.is_recommended ? "" : s.cardNotRecommended
      }`}
    >
      <div className={s.cardHeader}>
        <Link
          to={`/companyInfo?company=${review.company}&sector=&seniority=`}
          className={s.headerLeft}
        >
          <div className={s.imgArea}>
            <img
              alt={`Logo of ${review.company}`}
              src={`https://logo.clearbit.com/${review.company_domain}`}
            />
          </div>
          <span>
            {reviewCount &&
              `${reviewCount} Review${reviewCount > 1 ? "s" : ""}`}
          </span>
        </Link>
        <div className={s.headerRight}>
          <Link to={`/${review.p_id}?${searchParams}`} className={s.title}>
            {review.is_contacted_by_company ? (
              <span>
                <span className={s.name}>{review.company}</span> contacted{" "}
                <span className={s.name}>{review.username}</span>
              </span>
            ) : (
              <span>
                <span className={s.name}>{review.username}</span> contacted{" "}
                <span className={s.name}>{review.company}</span>
              </span>
            )}
          </Link>
          <div className={s.positionPill}>
            {`${review.seniority} ${review.sector}`}
          </div>
        </div>
      </div>
      <Link to={`/${review.p_id}?${searchParams}`}>
        {review.body && <div className={s.reviewText}>{review.body}</div>}
        <div className={s.cardFooter}>
          <div className={s.votes}>
            {review.votes > 1 ? (
              <span>{`Helpful for ${review.votes} people`}</span>
            ) : (
              <span>{`Helpful for ${review.votes} person`}</span>
            )}
          </div>
          <div className={s.user}>
            <span className="capitalize">– {review.username}</span>
            <span className={s.date}>{` ${new Date(
              review.date
            ).toLocaleDateString(navigator.languages[0])} `}</span>
          </div>
        </div>
      </Link>
    </div>
  );
};
export default Card;
