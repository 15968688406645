import Card from "./Card";
import s from "../styles/CardList.module.scss";
import { motion, useAnimationControls } from "framer-motion";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IInterview } from "../ts/interfaces";

interface CardListProps {
  title: string;
  cardArr: IInterview[];
  recommends: any;
  reverse?: boolean;
}
const CardList = ({ title, cardArr, recommends, reverse }: CardListProps) => {
  const matches = useMediaQuery("(min-width: 640px)");
  const duration = matches ? "50" : "150";
  const controls = useAnimationControls();
  const startMotion = () => {
    controls.start(
      reverse
        ? matches
          ? { x: "50%" }
          : { y: "50%" }
        : matches
        ? { x: "-50%" }
        : { y: "-50%" }
    );
  };
  startMotion();
  return (
    <section className={s.section}>
      <div className={s.sectionHeader}>
        <h2>{title}</h2>
        <Link to="/interviews?company=&sector=&seniority=">View all</Link>
      </div>
      {cardArr && (
        <div className={s.listWrapper}>
          <motion.ul
            animate={controls}
            transition={{
              repeat: Infinity,
              duration: duration,
              ease: "linear",
            }}
            className={`${s.cardList} ${reverse && s.reverse}`}
          >
            {cardArr.map((r: any, index: number) => (
              <li
                className={s.reviewCard}
                key={index}
                onMouseOver={() => {
                  controls.stop();
                }}
                onMouseLeave={startMotion}
              >
                <Card review={r} recommends={recommends} />
              </li>
            ))}
          </motion.ul>
          <div className={s.gradientStart} />
          <div className={s.gradientEnd} />
        </div>
      )}
    </section>
  );
};

export default CardList;

function useMediaQuery(query: string): boolean {
  const getMatches = (query: string): boolean => {
    // Prevents SSR issues
    if (typeof window !== "undefined") {
      return window.matchMedia(query).matches;
    }
    return false;
  };

  const [matches, setMatches] = useState<boolean>(getMatches(query));

  function handleChange() {
    setMatches(getMatches(query));
  }

  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    // Triggered at the first client-side load and if query changes
    handleChange();

    // Listen matchMedia
    if (matchMedia.addListener) {
      matchMedia.addListener(handleChange);
    } else {
      matchMedia.addEventListener("change", handleChange);
    }

    return () => {
      if (matchMedia.removeListener) {
        matchMedia.removeListener(handleChange);
      } else {
        matchMedia.removeEventListener("change", handleChange);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return matches;
}
