import {
  HandThumbDownIcon,
  HandThumbUpIcon,
  PlusCircleIcon,
} from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Layout from "../components/Layout";
import { requestApi } from "../helpers";
import { ICompany, IPayload } from "../ts/interfaces";
import s from "../styles/Company.module.scss";
import List from "../components/List";

const API_URL = process.env.REACT_APP_API_URL;

interface Result {
  sectorName: string;
  countRecommendTrue: number;
  isRecommended: boolean;
  stepsDone: number[];
  stepsMoney: number[];
  avgStepsDone: number;
  avgStepsMoney: number;
}

const CompanyPage = () => {
  const [companyInfo, setCompanyInfo] = useState<ICompany>();
  const [searchParams] = useSearchParams();

  const companyUrl = API_URL + "/companyInfo?" + searchParams;

  useEffect(() => {
    document.title = `Reviews of job interviews with ${companyInfo?.clearbit_data?.name}`;
  }, [companyInfo]);

  useEffect(() => {
    requestApi(companyUrl, setCompanyInfo);
  }, [searchParams, companyUrl]);

  function hasMoreTrueObjects(payload: IPayload[]) {
    const recommendedObjects = payload.filter(
      (object) => object.is_recommended
    );
    const recommendedObjectsCount = recommendedObjects.length;

    return recommendedObjectsCount > payload.length / 2;
  }
  function getAverage(field: "steps_done" | "steps_money") {
    let sum = 0;
    let count = 0;
    if (companyInfo) {
      for (const item of companyInfo.payload) {
        sum += item[field];
        count++;
      }
    }
    return Math.round(sum / count);
  }
  let isRecommended = false;
  let numberSteps = 0;
  let moneyDiscuss = 0;
  let sectors = [] as Result[];
  const sector = searchParams.get("sector");
  const seniority = searchParams.get("seniority");
  let title = "";
  if (sector) {
    title = ` for ${sector}`;
  } else if (seniority) {
    title = ` for ${seniority}`;
  } else if (sector && seniority) {
    title = ` for ${seniority} ${sector}`;
  }

  function getDataPerSector(payload: IPayload[]): Result[] {
    // An empty object to store the sector data
    const sectorData: { [sectorName: string]: Result } = {};

    // Loop through the payload to extract the data
    payload.forEach((entry) => {
      const sectorName = entry.sector;

      // If the sector is not in the sectorData object yet, initialize it with default values
      if (!sectorData[sectorName]) {
        sectorData[sectorName] = {
          sectorName: sectorName,
          countRecommendTrue: 0,
          isRecommended: false,
          stepsDone: [],
          stepsMoney: [],
          avgStepsDone: 0,
          avgStepsMoney: 0,
        };
      }

      // Increment the countTrue if the is_recommended is true
      if (entry.is_recommended) {
        sectorData[sectorName].countRecommendTrue++;
      }

      // Get the steps_done and steps_money values by sector
      sectorData[sectorName].stepsDone.push(entry.steps_done);
      sectorData[sectorName].stepsMoney.push(entry.steps_money);
    });

    // Calculate the average of steps_done and steps_money values by sector
    for (const sector in sectorData) {
      sectorData[sector].avgStepsDone = Math.floor(
        sectorData[sector].stepsDone.reduce((a, b) => a + b) /
          sectorData[sector].stepsDone.length
      );
      sectorData[sector].avgStepsMoney = Math.floor(
        sectorData[sector].stepsMoney.reduce((a, b) => a + b) /
          sectorData[sector].stepsMoney.length
      );
      // Set the isRecommended to true if the countTrue is greater than 0
      if (sectorData[sector].countRecommendTrue > 0) {
        sectorData[sector].isRecommended = true;
      }
    }

    // Return the sectorData as an array of objects
    return Object.values(sectorData);
  }

  if (companyInfo) {
    isRecommended = hasMoreTrueObjects(companyInfo.payload);
    numberSteps = getAverage("steps_done");
    moneyDiscuss = getAverage("steps_money");
    sectors = getDataPerSector(companyInfo.payload);
  }

  return (
    <Layout
      headerHref="/new"
      headerIcon={<PlusCircleIcon />}
      headerLabel="Add Review"
      heroSection
    >
      {companyInfo && (
        <>
          <div className={s.pageHeader}>
            <div className={s.title}>
              <img
                alt={`Logo of ${companyInfo.clearbit_data.logo}`}
                src={`https://logo.clearbit.com/${companyInfo.clearbit_data.logo}`}
              />
              <h1 className={s.pageTitle}>
                Reviews of job interviews with {companyInfo.clearbit_data.name}
              </h1>
            </div>
          </div>
          <div className={s.pageBody}>
            {companyInfo.payload.length > 0 && (
              <div className={s.section}>
                <div className={s.sectionHeader}>
                  <h2 className={s.sectionTitle}>
                    Overall experience of job interviews with{" "}
                    {companyInfo.clearbit_data.name}
                  </h2>
                  <span className={s.sectionSubtitle}>
                    Based on {companyInfo.payload.length} reviews
                  </span>
                </div>
                <div className={s.row_3}>
                  <div className={s.row__item}>
                    <h3 className={s.stat__header}>Interview experience</h3>
                    <p className={s.stat__body}>
                      {isRecommended ? (
                        <>
                          <HandThumbUpIcon /> Recommended
                        </>
                      ) : (
                        <>
                          <HandThumbDownIcon className={s.notRecommended} /> Not
                          Recommended
                        </>
                      )}
                    </p>
                  </div>
                  <div className={s.row__item}>
                    <h3 className={s.stat__header}>Interview steps</h3>
                    <p className={s.stat__body}>{numberSteps}</p>
                  </div>
                  <div className={s.row__item}>
                    <h3 className={s.stat__header}>Discussed money at step</h3>
                    <p className={s.stat__body}>{moneyDiscuss}</p>
                  </div>
                </div>
                <div className={s.rowFull}>
                  <ul className={s.arrowedList}>
                    {sectors.map((sector: Result, index: number) => (
                      <li key={index}>
                        <span>
                          {sector.sectorName}s{" "}
                          <strong>
                            {sector.isRecommended ? (
                              <span className={s.recommends}>recommend</span>
                            ) : (
                              <span className={s.notRecommends}>
                                do not recommend
                              </span>
                            )}{" "}
                          </strong>
                          applying, the interview usually had{" "}
                          <strong>{sector.avgStepsDone} steps</strong>, and they
                          discussed money at{" "}
                          <strong>step {sector.avgStepsMoney}</strong>.
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
            <div className={s.section}>
              {companyInfo.payload.length !== 0 && (
                <div className={s.sectionHeader}>
                  <h2 className={s.sectionTitle}>
                    Reviews of job interviews with{" "}
                    {companyInfo.clearbit_data.name}
                    {title}
                  </h2>
                </div>
              )}
              <div className={s.row_2}>
                <List />
              </div>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default CompanyPage;
