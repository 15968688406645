import s from "../styles/Button.module.scss";

type Props = {
  children: React.ReactNode;
  onClick: () => void;
  variant?: "primary" | "secondary" | "tertiary";
  size?: "base" | "lg" | "sm";
  color?: "brand";
  className?: string;
  disabled?: boolean;
};

const Button: React.FC<Props> = ({
  children,
  onClick,
  variant = "secondary",
  size = "base",
  color,
  disabled,
  className,
  ...rest
}) => {
  return (
    <button
      className={[
        s.btn,
        s[`${variant}`],
        s[`${size}`],
        s[`${color}`],
        s[`${className}`],
      ].join(" ")}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
