import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import FrontPage from "./pages/FrontPage";
import Detail from "./pages/Detail";
import New from "./pages/New";
import List from "./pages/List";
import "../node_modules/modern-normalize/modern-normalize.css";
import "./styles/index.scss";
import TextContent from "./pages/TextContent";
import Company from "./pages/Company";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<FrontPage />} />
        <Route path="/new" element={<New />} />
        <Route path="/interviews" element={<List />} />
        <Route path="/companyInfo" element={<Company />} />
        <Route path="/:id" element={<Detail />} />
        <Route path="/about" element={<TextContent type={"about"} />} />
        <Route path="/legal" element={<TextContent type={"legal"} />} />
        <Route path="/contact" element={<TextContent type={"contact"} />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
