import { ReactNode } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

interface Props {
  headerHref: string;
  headerIcon: ReactNode;
  headerLabel: string;
  headerButtonClass?: string;
  heroSection: boolean;
  footerButton?: boolean;
  children: ReactNode;
}

const Layout = ({
  headerHref,
  headerIcon,
  headerLabel,
  headerButtonClass,
  footerButton = true,
  heroSection,
  children,
}: Props) => {
  return (
    <div className="content">
      <Header
        href={headerHref}
        icon={headerIcon}
        label={headerLabel}
        buttonClass={headerButtonClass}
        heroSection={heroSection}
      />
      <main>{children}</main>
      <Footer footerButton={footerButton} />
    </div>
  );
};

export default Layout;
